import styled from '@emotion/styled';
import Markdown from 'markdown-to-jsx';

import { MAX_MOB_WIDTH, MAX_TABLET_WIDTH } from 'constants/sizes';
import { Layout, Article as UIArticle, FileLink as UIFileLink } from 'UI';

import ImageSlider from 'components/blocks/PressRelease/ImagesSlider/ImagesSlider';

import { HeaderOne } from 'UI/Article/styled';
import { IconWrapper } from 'UI/FileLink/FileLink.styled';

export const Container = styled.div`
  min-height: 100vh;
`;

export const Wrapper = styled(Layout)`
  padding-top: 100px;
  padding-bottom: 100px;
`;

export const Prefence = styled(Markdown)`
  grid-column: 5/-1;
  row-gap: 1em;
  display: grid;
  grid-auto-flow: row;
  margin-bottom: 5rem;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Article = styled(UIArticle)`
  grid-column: 5/-1;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 2/-1;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    grid-column: 1/-1;
  }

  p,
  ul,
  li {
    + ${HeaderOne} {
      margin-top: 5rem;

      + * {
        margin-top: 5rem;
      }
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      + ${HeaderOne} {
        margin-top: 2rem;

        + * {
          margin-top: 1rem;
        }
      }
    }
  }
`;

export const Department = styled.p`
  color: var(--red);
`;

export const Title = styled.h3`
  grid-column: 1/9;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    grid-column: 1/-1;
  }
`;

export const Slider = styled(ImageSlider)`
  margin-top: 5em;
  grid-column: 1/-1;
  margin-bottom: 5rem;
  grid-row: 2;
`;

export const FileLink = styled(UIFileLink)`
  :hover {
    color: var(--black-opacity);

    ${IconWrapper} {
      background: var(--black-opacity);
    }
  }
`;

export const Files = styled.div`
  position: sticky;
  top: 2rem;
`;

export const FilesWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 3/8;
  justify-self: start;
`;
