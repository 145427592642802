import React, { FC } from 'react';
import { PageProps, graphql } from 'gatsby';

import Head from 'components/Layout/Head/Head';

import { IPressRelease } from 'interfaces/press';
import { IMediaImage } from 'interfaces/media';

import {
  Container, Wrapper, Title, Article, Slider, Prefence, Files, FilesWrapper, FileLink,
} from './release.styled';

interface IPressReleaseQuery extends IPressRelease {
  metaImage: IMediaImage[];
}

interface IPressReleasePage extends PageProps {
  data: {
    strapiPressReleases: IPressReleaseQuery;
  };
}

const PressReleasePage: FC<IPressReleasePage> = ({ data: { strapiPressReleases } }) => {
  const {
    header, media, article, file, slug, metaImage,
  } = strapiPressReleases;

  const description = article?.preface[0]?.text;
  const formattedDescription = description?.replace(/[*]{2}/gi, '.').replace(/^./, '').trim();

  return (
    <>
      <Head
        title={header}
        description={formattedDescription || ''}
        preview={metaImage[0]}
        slug={`press-releases/${slug}`}
      />
      <Container>
        <Wrapper withPaddings>
          <Title>{ header }</Title>
          <Slider media={media} />
          <FilesWrapper>
            <Files>
              <FileLink
                link={file.localFile.publicURL}
                text="Download full pack"
                title={file.alternativeText}
              />
            </Files>
          </FilesWrapper>
          {article?.preface.map(({ text, id }) => (
            <Prefence key={id}>
              {text}
            </Prefence>
          ))}
          <Article
            text={article?.article}
          />
        </Wrapper>
      </Container>
    </>
  );
};

export const query = graphql`
  query($slug: String) {
    strapiPressReleases(slug: { eq: $slug }) {
      title
      header
      slug
      id: strapiId
      media {
        ...MediaImageFragment
      }
      metaImage: media {
        id
        localFile {
          childImageSharp {
            gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 98)
          }
          publicURL
        }
      }
      article {
        ...StrapiArticleFragment
      }
      file {
        localFile {
          publicURL
        }
        alternativeText
      }
      date(formatString: "MMM DD, YYYY")
    }
  }
`;

export default PressReleasePage;
