import React, { FC, useRef } from 'react';

import { IMediaImage } from 'interfaces/media';

import {
  Wrapper, Slider, Image,
} from './ImagesSlider.styled';

interface IImageSlider {
  media: IMediaImage[];
  className?: string;
}

const slideWidth = 80;

const ImageSlider: FC<IImageSlider> = ({ media, className }) => {
  const containerRef = useRef<HTMLDivElement>();
  if (!media.length) return null;

  const items = media.map(({ id }) => `${id}`);

  return (
    <Wrapper className={className}>
      {media.length > 1 ? (
        <>
          <Slider
            ref={containerRef}
            items={items}
            itemWidth={slideWidth}
          >
            {media.map((item) => <Image key={item.id} {...item} />)}
          </Slider>
        </>
      ) : (
        <Image
          width={slideWidth}
          {...media[0]}
        />
      )}
    </Wrapper>
  );
};

export default ImageSlider;
