import { css } from '@emotion/react';
import styled from '@emotion/styled';

import {
  MIN_4K,
  MIN_2K,
  MAX_TABLET_WIDTH,
  MAX_MOB_WIDTH,
} from 'constants/sizes';
import { GatsbyPicture, TouchSlider } from 'UI';
import { Wrapper as TouchWrapper } from 'UI/TouchSlider/TouchSlider.styled';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  margin-left: -80px;
  margin-right: -80px;

  @media (max-width: ${MAX_TABLET_WIDTH}px) {
    margin-left: -48px;
    margin-right: -48px;
  }

  @media (max-width: ${MAX_MOB_WIDTH}px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media (min-width: ${MIN_2K}px) {
    margin-left: -120px;
    margin-right: -120px;
  }

  @media (min-width: ${MIN_4K}px) {
    margin-left: -240px;
    margin-right: -240px;
  }
`;

interface IImage {
  width?: number;
}

export const Image = styled(GatsbyPicture)<IImage>`
  display: block;
  background: var(--black);
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.6%;

  ${({ width }) => width && css`
    padding-right: ${100 - width}%;
  `}

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Slider = styled(TouchSlider)`
  background: var(--black);

  ${TouchWrapper} {
    gap: 0;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
      gap: 0;
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      gap: 0;
    }

    @media (min-width: ${MIN_4K}px) {
      gap: 0;
    }
  }
`;
